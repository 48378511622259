import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Autocomplete, AutocompleteProps, CircularProgress, TextField, TextFieldProps } from '@mui/material';
import React, { useMemo } from 'react';
import authenticationApi from '../../../features/authentication/authenticationApi';
import { INACTIVE_COMPANY_VIEW } from '../../../features/roles/permissionsList';
import { isFeatureAccessible } from '../../utils/commonUtils';
import componentsApi from '../componentsApi';
import { Company } from '../componentsModel';

interface WrappedAutoCompleteProps
    extends Omit<AutocompleteProps<Company, undefined, boolean, undefined>, 'options' | 'renderInput' | 'value'> {
    textFieldProps?: TextFieldProps;
    value: string;
}

const getLabel = (input: string | Company) => {
    if (typeof input === 'string') {
        return '';
    }

    return `${input.mom} - ${input.name}`;
};

const arrowDropDownIcon = <ArrowDropDownIcon />;

const circularProgress = <CircularProgress color="inherit" size={20} />;

//V2 -> V3: updates props and make component controlled
export default function CompanyDropdownV3({ textFieldProps, value, ...props }: WrappedAutoCompleteProps) {
    const getUserPermissions = authenticationApi.endpoints.getUserPermissions.useQuery();
    const userPermissions: string[] = getUserPermissions.data || [];

    const shouldGetOnlyActiveClients = !isFeatureAccessible(userPermissions, INACTIVE_COMPANY_VIEW);
    const getCompaniesResponse = componentsApi.endpoints.getCompanies.useQuery(shouldGetOnlyActiveClients);

    const isLoading = getCompaniesResponse.isFetching || getUserPermissions.isFetching;

    const companyMatchingControllValue = useMemo(() => {
        return getCompaniesResponse?.data?.find(company => company.mom === value);
    }, [value, getCompaniesResponse]);

    const sortedCompanies = useMemo(() => {
        if (getCompaniesResponse.isSuccess) {
            let arrayToSort = [...getCompaniesResponse.data];
            const result = arrayToSort.sort((a, b) => {
                if (a.mom < b.mom) {
                    return -1;
                }
                if (a.mom > b.mom) {
                    return 1;
                }
                return 0;
            });

            return result;
        } else {
            return [];
        }
    }, [getCompaniesResponse.isSuccess]);

    return (
        <Autocomplete
            key={companyMatchingControllValue?.mom || ''}
            value={companyMatchingControllValue}
            options={sortedCompanies}
            getOptionLabel={getLabel}
            loading={isLoading}
            popupIcon={props.readOnly ? '' : arrowDropDownIcon}
            renderInput={params => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? circularProgress : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    {...textFieldProps}
                />
            )}
            {...props}
        />
    );
}
