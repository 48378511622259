import { Box, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import React from 'react';
import serialLocationApi from '../serialLocationApi';
import SerialLocationDataGrid from './SerialLocationDataGrid';
import useNotificationByResponse from '../../../common/hooks/useNotificationByResponse';

const SerialLocationElement = () => {
    const [
        triggerGetSerialLocation,
        getSerialLocationResponse,
    ] = serialLocationApi.endpoints.getSerialLocations.useLazyQuery();

    useNotificationByResponse({
        isFetching: getSerialLocationResponse.isFetching,
        isError: getSerialLocationResponse.isError,
        errorContent: 'Failed to get',
        isUninitialized: getSerialLocationResponse.isUninitialized,
    });

    const [locationName, setLocationName] = React.useState('');
    const [serialValue, setSerialValue] = React.useState('');
    const [isShelved, setIsShelved] = React.useState(true);

    return (
        <Box sx={{ backgroundColor: 'white', p: 2 }}>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    triggerGetSerialLocation({ isShelved, locationName, serialValue });
                }}
            >
                <Box sx={{ display: 'flex' }}>
                    <TextField
                        variant="outlined"
                        label="Location Name"
                        value={locationName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const newValue = event.target.value;
                            setLocationName(newValue);
                        }}
                        sx={{ flex: 3, m: 1 }}
                    />

                    <TextField
                        variant="outlined"
                        label="Serial Value"
                        value={serialValue}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const newValue = event.target.value;
                            setSerialValue(newValue);
                        }}
                        sx={{ flex: 3, m: 1 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked
                                value={isShelved}
                                onChange={event => {
                                    const newValue = event.target.checked;
                                    setIsShelved(newValue);
                                }}
                            />
                        }
                        label="Shelved"
                        sx={{ px: 3 }}
                    />
                </Box>
                <Box sx={{ display: 'flex', m: 1, mb: 5 }}>
                    <Button type="submit" sx={{ flex: 1 }} variant="contained">
                        Filter
                    </Button>
                </Box>
            </form>

            <SerialLocationDataGrid data={getSerialLocationResponse.data || []} />
        </Box>
    );
};

export default SerialLocationElement;
