import { Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import React from 'react';
import { useParams } from 'react-router';
import LoadingCard from '../../../../../common/components/Card/LoadingCard';
import useNotificationByQueryResponse from '../../../../../common/hooks/useNotificationByQueryResponse';
import useGetCreatedProcedureRecord from '../../../hooks/useGetCreatedProcedureRecord';
import ProcedureApi from '../../../ProcedureApi';
import InteractableMediaCard from './InteractableMediaCard';

const Instruction = () => {
    const { workOrderToProcedureMapId } = useParams();
    const castedWorkOrderToProcedureMapId = workOrderToProcedureMapId ? Number(workOrderToProcedureMapId) : null;

    const getActiveSessionResponse = ProcedureApi.endpoints.getActiveSession.useQuery(
        castedWorkOrderToProcedureMapId ? castedWorkOrderToProcedureMapId : skipToken
    );

    const procedureResponse = useGetCreatedProcedureRecord();
    const firstInstruction = procedureResponse?.data?.Instructions.find(i => i.IsStartOfProcedure)?.Id || null;

    const [previousInstructionIds, setPreviousInstructionIds] = React.useState<number[]>([]);
    const [currentInstructionId, setCurrentInstructionId] = React.useState<number | null>(
        getActiveSessionResponse?.data?.CurrentInstructionId ||
            firstInstruction ||
            procedureResponse?.data?.Instructions?.[0]?.Id ||
            null
    );

    const [triggerContinueSession, continueSessionResponse] = ProcedureApi.endpoints.continueSession.useMutation();

    const currentInstruction = procedureResponse?.data?.Instructions?.find(i => i.Id === currentInstructionId);

    React.useEffect(() => {
        if (!currentInstructionId) {
            const firstInstruction = procedureResponse?.data?.Instructions.find(i => i.IsStartOfProcedure);
            setCurrentInstructionId(firstInstruction?.Id || procedureResponse?.data?.Instructions?.[0]?.Id || null);
        }
    }, [procedureResponse]);

    useNotificationByQueryResponse({
        response: procedureResponse,
        successFinishedFunction: () => {
            if (!currentInstructionId) {
                const firstInstruction = procedureResponse?.data?.Instructions.find(i => i.IsStartOfProcedure);
                setCurrentInstructionId(firstInstruction?.Id || procedureResponse?.data?.Instructions?.[0]?.Id || null);
            }
        },
    });

    const setCurrentInstructionIdToPrevious = () => {
        const nextInstructionId = previousInstructionIds[previousInstructionIds.length - 1];
        setCurrentInstructionId(nextInstructionId);
        setPreviousInstructionIds(previousInstructionIds =>
            previousInstructionIds.slice(0, previousInstructionIds.length - 1)
        );
        if (castedWorkOrderToProcedureMapId)
            triggerContinueSession({
                CurrentInstructionId: nextInstructionId,
                WorkOrderToProcedureMapId: castedWorkOrderToProcedureMapId,
            });
    };

    const goToNextInstruction = (newInstructionId: number, previousInstructionId: number) => {
        setPreviousInstructionIds(previousInstructionIds => [...previousInstructionIds, previousInstructionId]);
        setCurrentInstructionId(newInstructionId);

        if (castedWorkOrderToProcedureMapId)
            triggerContinueSession({
                CurrentInstructionId: newInstructionId,
                WorkOrderToProcedureMapId: castedWorkOrderToProcedureMapId,
            });
    };

    if (procedureResponse.isFetching) return <LoadingCard />;

    if (currentInstruction)
        return (
            <InteractableMediaCard
                instruction={currentInstruction}
                shouldRenderBackButton={previousInstructionIds.length > 0}
                handleBackButtonClick={setCurrentInstructionIdToPrevious}
                handleNextButtonClick={goToNextInstruction}
                // key={JSON.stringify(currentInstruction)}
            />
        );

    if (procedureResponse.data?.Instructions.length === 0)
        return <Typography>There are no instructions to display</Typography>;
};

export default Instruction;
