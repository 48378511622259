import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { setDialog } from '../../../../notification/notificationSlice';
import AddItemDialog from '../../../../procedure/components/Canvas/AddItemDialog';
import { SkuAdjustment, TargetReceiverItemAmount } from '../../../../procedure/models';
import { WorkOrderToProcedureMap } from '../../../workOrderModels';
import { overrideForm } from '../../../workOrderSlice';

const ProcedureAddItemDialog = ({
    isOpen,
    workOrderToProcedureMap,
    onClose,
}: {
    isOpen: boolean;
    workOrderToProcedureMap: WorkOrderToProcedureMap;
    onClose: () => void;
}) => {
    const workOrderToProcedureMaps = useAppSelector(state => state.workOrder.form.WorkOrderToProcedureMaps);
    const client = useAppSelector(state => state.workOrder.form.MomCode);
    const dispatch = useAppDispatch();

    const matchingWorkorderToProcedureMapIndex = workOrderToProcedureMaps.findIndex(
        map => map.Id === workOrderToProcedureMap.Id
    );

    const handleAddNewItem = (skuAdjustment: SkuAdjustment) => {
        if (
            workOrderToProcedureMaps[matchingWorkorderToProcedureMapIndex].TargetReceiverItemAmounts.some(
                amount => amount.Sku === skuAdjustment.Sku
            )
        ) {
            dispatch(setDialog({ content: 'Cannot add same sku', title: 'Invalid SKU' }));
            return;
        }

        let newProcedure = { ...workOrderToProcedureMaps[matchingWorkorderToProcedureMapIndex] };

        const newTargetReceiverItemAmount = {
            Sku: skuAdjustment.Sku,
            AdjustmentAmount: skuAdjustment.AdjustmentAmount,
        } as TargetReceiverItemAmount;
        let newTargetReceiverItemAmounts = [...newProcedure.TargetReceiverItemAmounts, newTargetReceiverItemAmount];

        newProcedure.TargetReceiverItemAmounts = newTargetReceiverItemAmounts;

        const newProceduresList = [...workOrderToProcedureMaps];
        newProceduresList[matchingWorkorderToProcedureMapIndex] = newProcedure;

        dispatch(
            overrideForm({
                WorkOrderToProcedureMaps: newProceduresList,
            })
        );
    };

    const handleItemDelete = (sku: string) => {
        let newProcedure = { ...workOrderToProcedureMaps[matchingWorkorderToProcedureMapIndex] };

        const itemIndexToRemove = newProcedure?.TargetReceiverItemAmounts.findIndex(item => item.Sku === sku);
        let newItemsList = [...newProcedure?.TargetReceiverItemAmounts];
        if (newProcedure?.TargetReceiverItemAmounts[itemIndexToRemove]?.Id) {
            newItemsList[itemIndexToRemove] = {
                ...newItemsList[itemIndexToRemove],
                DeletedAt: dayjs().format(),
            };
        } else {
            newItemsList.splice(itemIndexToRemove, 1);
        }

        newProcedure.TargetReceiverItemAmounts = newItemsList;

        let newProceduresList = [...workOrderToProcedureMaps];
        newProceduresList[matchingWorkorderToProcedureMapIndex] = newProcedure;

        dispatch(overrideForm({ WorkOrderToProcedureMaps: newProceduresList }));
    };
    const processRowUpdate = (skuAdjustment: SkuAdjustment) => {
        let newProcedure = { ...workOrderToProcedureMaps[matchingWorkorderToProcedureMapIndex] };

        const itemIndexToRemove = newProcedure?.TargetReceiverItemAmounts.findIndex(
            item => item.Sku === skuAdjustment.Sku
        );

        let newItemsList = [...newProcedure?.TargetReceiverItemAmounts];

        newItemsList[itemIndexToRemove] = {
            ...newItemsList[itemIndexToRemove],
            ...skuAdjustment,
        };

        newProcedure.TargetReceiverItemAmounts = newItemsList;

        let newProceduresList = [...workOrderToProcedureMaps];
        newProceduresList[matchingWorkorderToProcedureMapIndex] = newProcedure;

        dispatch(overrideForm({ WorkOrderToProcedureMaps: newProceduresList }));
    };

    return (
        <AddItemDialog
            isDialogOpen={isOpen}
            client={client || ''}
            onDialogClose={onClose}
            items={workOrderToProcedureMap?.TargetReceiverItemAmounts?.filter(amount => !amount.DeletedAt) || []}
            onAddNewItem={handleAddNewItem}
            onProcessRowUpdate={processRowUpdate}
            onRowDelete={handleItemDelete}
        />
    );
};

export default ProcedureAddItemDialog;
