import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';

const NumberTextfield = ({
    value,
    onChange,
    props = {},
}: {
    value: number;
    onChange: (newValue: number) => void;
    props?: TextFieldProps;
}) => {
    return (
        <TextField
            value={value?.toString()}
            type="number"
            variant="outlined"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = parseInt(event.target.value) || 0;
                onChange(newValue);
            }}
            {...props}
        />
    );
};

export default NumberTextfield;
