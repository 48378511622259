import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAppDispatch } from '../../../../../app/store';
import useNotificationByMutationResponseV2 from '../../../../../common/hooks/useNotificationByMutationResponseV2';
import { setDialog } from '../../../../notification/notificationSlice';
import ProcedureApi from '../../../../procedure/ProcedureApi';
import { WorkOrderToProcedureMap } from '../../../workOrderModels';
import SkuAdjustmentTable from './SkuAdjustmentTable';

const CreateReceiverDialog = ({
    isOpen,
    workOrderToProcedureMap,
    onClose,
}: {
    isOpen: boolean;
    workOrderToProcedureMap: WorkOrderToProcedureMap;
    onClose: () => void;
}) => {
    const dispatch = useAppDispatch();
    const [triggerCreateReceiver, createReceiverResponse] = ProcedureApi.endpoints.createReceiver.useMutation();

    const getWorkOrderToProcedureMapResponse = ProcedureApi.endpoints.getWorkOrderToProcedureMap.useQuery(
        workOrderToProcedureMap.Id ? workOrderToProcedureMap.Id : skipToken
    );

    const handleSubmit = () => {
        //TODO: implement doesHaveItemWithNonZeroQuantity
        const doesHaveItemWithNonZeroQuantity = false;
        if (doesHaveItemWithNonZeroQuantity) {
            dispatch(
                setDialog({
                    content: 'Can not create receiver with only items of zero quantity',
                    title: 'No items completed',
                })
            );
            return;
        }

        if (workOrderToProcedureMap?.Id)
            triggerCreateReceiver({ WorkOrderToProcedureMapId: workOrderToProcedureMap.Id });
    };

    useNotificationByMutationResponseV2({
        response: createReceiverResponse,
        successFinishedFunction: onClose,
        successMessage: `Successfully created receiver #${createReceiverResponse.data}`,
        errorMessage: 'Failed to create receiver',
    });

    return (
        <Dialog onClose={onClose} open={isOpen} maxWidth={'lg'}>
            <DialogTitle>Confirm Receiver Creation</DialogTitle>
            <DialogContent>
                <SkuAdjustmentTable
                    targetReceiverItemAmounts={
                        getWorkOrderToProcedureMapResponse?.data?.Instructions.flatMap(
                            instruction => instruction.ReceiverItems
                        ) || []
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSubmit}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateReceiverDialog;
