import { CARD_HEIGHT, CARD_WIDTH } from './components/Canvas/InstructionCard';
import { DraggableInstruction } from './models';

export const getArrowPoints = (startingPoint: DraggableInstruction, endingPoint: DraggableInstruction): number[] => {
    const xDifference = (startingPoint.CanvasPositionX || 0) - (endingPoint.CanvasPositionX || 0);
    const yDifference = (startingPoint.CanvasPositionY || 0) - (endingPoint.CanvasPositionY || 0);

    const isXMoreExtreme = Math.abs(xDifference) > Math.abs(yDifference);
    const isYMoreExtreme = !isXMoreExtreme;

    const isStartingPointLeftOfEndingPoint = xDifference < 0;
    const isStartingPointRightOfEndingPoint = !isStartingPointLeftOfEndingPoint;
    const isStartingPointAboveOfEndingPoint = yDifference < 0;
    const isStartingPointBelowOfEndingPoint = !isStartingPointAboveOfEndingPoint;

    if (isXMoreExtreme && isStartingPointLeftOfEndingPoint)
        return [...rightOfRectangle(startingPoint), ...leftOfRectangle(endingPoint)];

    if (isXMoreExtreme && isStartingPointRightOfEndingPoint)
        return [...leftOfRectangle(startingPoint), ...rightOfRectangle(endingPoint)];

    if (isYMoreExtreme && isStartingPointAboveOfEndingPoint)
        return [...bottomOfRectangle(startingPoint), ...topOfRectangle(endingPoint)];

    if (isYMoreExtreme && isStartingPointBelowOfEndingPoint)
        return [...topOfRectangle(startingPoint), ...bottomOfRectangle(endingPoint)];

    return [
        startingPoint.CanvasPositionX || 0,
        startingPoint.CanvasPositionY || 0,
        endingPoint.CanvasPositionX || 0,
        endingPoint.CanvasPositionY || 0,
    ];
};

const topOfRectangle = (point: DraggableInstruction): number[] => {
    return [(point.CanvasPositionX || 0) + CARD_WIDTH / 2, point.CanvasPositionY || 0];
};

const bottomOfRectangle = (point: DraggableInstruction): number[] => {
    return [(point.CanvasPositionX || 0) + CARD_WIDTH / 2, (point.CanvasPositionY || 0) + CARD_HEIGHT];
};

const leftOfRectangle = (point: DraggableInstruction): number[] => {
    return [point.CanvasPositionX || 0, (point.CanvasPositionY || 0) + CARD_HEIGHT / 2];
};

const rightOfRectangle = (point: DraggableInstruction): number[] => {
    return [(point.CanvasPositionX || 0) + CARD_WIDTH, (point.CanvasPositionY || 0) + CARD_HEIGHT / 2];
};
