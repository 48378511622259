import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Autocomplete, AutocompleteProps, CircularProgress, TextField, TextFieldProps } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import React from 'react';
import { SubmissionFormItem } from '../../../features/inbound/receiversModels';
import receiversApi from '../../../features/receivers/receiversApi';

interface WrappedAutoCompleteProps
    extends Omit<
        AutocompleteProps<SubmissionFormItem, undefined, boolean, undefined>,
        'options' | 'renderInput' | 'value'
    > {
    textFieldProps?: TextFieldProps;
    value: string;
    client: string;
}

const getLabel = (input: string | SubmissionFormItem) => {
    if (typeof input === 'string') {
        return '';
    }

    return `${input.Item}: ${input.Descript}`;
};

const arrowDropDownIcon = <ArrowDropDownIcon />;

const circularProgress = <CircularProgress color="inherit" size={20} />;

//V1 -> V2: Use user code as value
export default function ItemDropdown({ textFieldProps, value, client, ...props }: WrappedAutoCompleteProps) {
    const response = receiversApi.endpoints.getReceiverStocks.useQuery(client ? client : skipToken);

    const itemMatchingControllValue = React.useMemo(() => {
        return response?.data?.find(item => item.Item === value);
    }, [value, response]);

    const sortedItems = React.useMemo(() => {
        if (response.isSuccess) {
            return [...response?.data].sort((a, b) => a?.Item?.localeCompare(b?.Item));
        }
        return [];
    }, [response.isSuccess]);

    return (
        <Autocomplete
            key={itemMatchingControllValue?.Item || ''}
            value={itemMatchingControllValue}
            options={sortedItems}
            getOptionLabel={getLabel}
            loading={response.isFetching}
            popupIcon={props.readOnly ? '' : arrowDropDownIcon}
            renderInput={params => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {response.isFetching ? circularProgress : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    {...textFieldProps}
                />
            )}
            {...props}
        />
    );
}
