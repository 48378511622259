import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useAppSelector } from '../../../../../app/store';
import ProceduresListRow from './ProceduresListRow';

const ProceduresList = ({
    onRowClick,
    isDisabled,
}: {
    onRowClick: (procedureId: number) => void;
    isDisabled: boolean;
}) => {
    const workOrderToProcedureMaps = useAppSelector(state => state.workOrder.form.WorkOrderToProcedureMaps) || [];

    const activeWorkOrderToProcedureMaps = workOrderToProcedureMaps.filter(map => !map.DeletedAt);

    return (
        <Box sx={{ my: 2, display: activeWorkOrderToProcedureMaps?.length ? '' : 'none' }}>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Title</TableCell>
                            <TableCell>Created By</TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right" sx={{ display: isDisabled ? 'none' : '' }}>
                                Delete
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ cursor: isDisabled ? '' : 'pointer' }}>
                        {activeWorkOrderToProcedureMaps.map(map => (
                            <ProceduresListRow key={map.Id} workOrderToProcedureMap={map} isDisabled={isDisabled} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ProceduresList;
