import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { UNASSIGNED_TEMPLATE_TYPE_ID } from './constants';
import { WorkOrderData } from './workOrderModels';
import { setTab } from './workOrderSlice';

export const cleanTemplateData = (data: WorkOrderData): WorkOrderData => {
    return {
        ...data,
        Id: null,
        DateCreated: dayjs().format(),
        DueDate: dayjs().add(1, 'week').format(),
        DateCompleted: null,
        CompletedBy: null,
        DeletedDate: null,
        DeletedBy: null,
        AttachmentItems: data.AttachmentItems.map(item => {
            return { ...item, Id: null, WorkOrderId: null };
        }),

        BillingItems: data.BillingItems.map(item => {
            return { ...item, Id: null, WorkOrderId: null };
        }),

        BillsOfMaterialsItems: data.BillsOfMaterialsItems.map(item => {
            return { ...item, Id: null, WorkOrderId: null };
        }),

        EditLogItems: [],
    };
};

export const setToTemplatesTab = (dispatch: Dispatch<AnyAction>) => {
    dispatch(setTab('Templates'));
};

export const setToWorkOrderTab = (dispatch: Dispatch<AnyAction>) => {
    dispatch(setTab('Work Orders'));
};
