import { TextField } from '@mui/material';
import { useState } from 'react';
import { CompanyDropdown } from '../../../common/components';
import InputDialog from '../../../common/components/Dialog/InputDialog';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { setGetRmaParameters } from '../rmaSlice';
import rmaApi from '../rmaApi';
import { setDialog } from '../../notification/notificationSlice';
import useScanner from '../../qualityControl/hooks/useScanner';

const RmaInputDialog = () => {
    const rmaState = useAppSelector(state => state.rma);
    const [momCodeState, setMomCodeState] = useState(rmaState?.momCode ? rmaState.momCode : '');
    const [rmaNumber, setRmaNumber] = useState(0);

    const [getRmaItemsTrigger, getRmaItemsResponse] = rmaApi.endpoints.getRmaItems.useLazyQuery();
    const dispatch = useAppDispatch();

    const handleSubmit = () => {
        if (momCodeState === '' || rmaNumber === 0) {
            dispatch(setDialog({ content: '', title: 'Invalid Input' }));
            return;
        }

        dispatch(setGetRmaParameters({ momCode: momCodeState || rmaState.momCode, rmaNumber: rmaNumber }));
        getRmaItemsTrigger({ momCode: momCodeState || rmaState.momCode, rmaNumber: rmaNumber });
        setRmaNumber(0);
    };

    useScanner(rmaNumber, handleSubmit);

    return (
        <InputDialog
            title={'RMA'}
            isHidden={
                !getRmaItemsResponse.isUninitialized &&
                getRmaItemsResponse.data?.Items &&
                getRmaItemsResponse.data.Items.length > 0
            }
            bodyElement={
                <>
                    <CompanyDropdown
                        startingMom={rmaState?.momCode ? rmaState.momCode : ''}
                        updateSelectedCompany={(newValue: any) => {
                            setMomCodeState(newValue?.mom);
                        }}
                        key="select-company"
                        testId="company-dropdown-autocomplete"
                        autoFocus={!rmaState?.momCode}
                    />
                    <TextField
                        label="Input RMA"
                        sx={{ width: '100%', mt: 2 }}
                        value={rmaNumber.toString()}
                        autoFocus={!!rmaState?.momCode}
                        type="number"
                        onChange={event => {
                            const result = parseInt(event.target.value) || 0;
                            if (result >= 0) setRmaNumber(result);
                        }}
                    />
                </>
            }
            handleSubmit={handleSubmit}
        />
    );
};

export default RmaInputDialog;
