import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { overrideForm } from '../../../receiversSlice';
import ReceiverDialogNumberTextField from './ReceiverDialogNumberTextField';
import { ActiveRuleMap } from '../../../receiversModels';

const expandIcon = <ExpandMoreIcon />;

const LaborTime = ({ activeRules }: { activeRules: ActiveRuleMap }) => {
    const StockTime = useAppSelector(state => state.receivers.form.StockTime);
    const LabelTime = useAppSelector(state => state.receivers.form.LabelTime);
    const ShelfTime = useAppSelector(state => state.receivers.form.ShelfTime);
    const dispatch = useAppDispatch();

    return (
        <Accordion sx={{ flex: 1 }} elevation={8}>
            <AccordionSummary expandIcon={expandIcon} aria-controls="labor-time-content" id="labor-time-header">
                Labor Time
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <ReceiverDialogNumberTextField
                        value={StockTime}
                        onChange={newValue => {
                            dispatch(overrideForm({ StockTime: newValue }));
                        }}
                        textFieldProps={{
                            label: 'Stock Time (mins)',
                        }}
                        rule={activeRules.StockTime}
                    />

                    <ReceiverDialogNumberTextField
                        value={LabelTime}
                        onChange={newValue => {
                            dispatch(overrideForm({ LabelTime: newValue }));
                        }}
                        textFieldProps={{
                            label: 'Label Time (mins)',
                        }}
                        rule={activeRules.LabelTime}
                    />

                    <ReceiverDialogNumberTextField
                        value={ShelfTime}
                        onChange={newValue => {
                            dispatch(overrideForm({ ShelfTime: newValue }));
                        }}
                        textFieldProps={{
                            label: 'Shelf Time (mins)',
                        }}
                        rule={activeRules.ShelfTime}
                    />
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default React.memo(LaborTime);
