import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import useNotificationByQueryResponse from '../../../../../common/hooks/useNotificationByQueryResponse';
import { setDialog } from '../../../../notification/notificationSlice';
import { SimplifiedProcedureRecord } from '../../../../procedure/models';
import Procedure from '../../../../procedure/Procedure';
import ProcedureApi from '../../../../procedure/ProcedureApi';
import { overrideForm } from '../../../workOrderSlice';

const ConnectProcedureDialog = ({
    isDialogOpen,
    onClose,
}: {
    isDialogOpen: boolean;
    onClose: (clickedProcedureId?: number) => void;
}) => {
    const workOrderToProcedureMaps = useAppSelector(state => state.workOrder.form.WorkOrderToProcedureMaps);
    const momCode = useAppSelector(state => state.workOrder.form.MomCode);
    const [triggerGetProcedure, getProcedureResponse] = ProcedureApi.endpoints.getProcedure.useLazyQuery();
    const dispatch = useAppDispatch();

    const handleAddProcedure = (procedure: SimplifiedProcedureRecord) => {
        if (procedure.Client !== momCode) {
            dispatch(
                setDialog({
                    content: 'Must select work order client that matches SWROP client',
                    title: 'Invalid client',
                })
            );
            return;
        }

        triggerGetProcedure(procedure.Id);
    };

    useNotificationByQueryResponse({
        response: getProcedureResponse,
        finishedFunction: () => {
            const detailedProcedure = getProcedureResponse?.data;
            if (!detailedProcedure) return;

            dispatch(
                overrideForm({
                    WorkOrderToProcedureMaps: [
                        ...(workOrderToProcedureMaps || []),
                        {
                            Id: null,
                            WorkOrderId: null,
                            ProcedureId: detailedProcedure.Id,
                            TargetReceiverItemAmounts: [],
                            DeletedAt: null,
                        },
                    ],
                })
            );

            onClose(detailedProcedure.Id);
        },
    });

    return (
        <Dialog
            open={isDialogOpen}
            onClose={() => {
                onClose();
            }}
            fullWidth
        >
            <DialogTitle>
                <Typography variant="h5">Select Procedure To Add</Typography>
            </DialogTitle>
            <DialogContent>
                {<Procedure shouldRenderCreateButton={false} onRowClick={handleAddProcedure} />}
            </DialogContent>
        </Dialog>
    );
};

export default ConnectProcedureDialog;
