import { TextField } from '@mui/material';

import React, { FC, memo } from 'react';

interface MemoNumberTextFieldProps {
    label: string;
    step?: number;
    value: number;
    onChange: (newValue: number) => void;
    disabled?: boolean;
    error?: boolean;
}

const MemoNumberTextField: FC<MemoNumberTextFieldProps> = memo(
    ({ label, step = 1, value, onChange, disabled = false, error = false }) => {
        return (
            <TextField
                value={value?.toString() || ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = Number(event.target.value) || 0;

                    if (newValue >= 0) onChange(newValue);
                }}
                label={label}
                variant="outlined"
                type="number"
                key="label"
                sx={{ mt: 2, width: '100%' }}
                inputProps={{
                    step: step,
                }}
                disabled={disabled}
                error={error}
            />
        );
    },
    arePropsEqual
);

function arePropsEqual(oldProps: MemoNumberTextFieldProps, newProps: MemoNumberTextFieldProps) {
    return oldProps.value === newProps.value;
}

export default MemoNumberTextField;
