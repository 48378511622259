import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Button, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import React from 'react';
import { useAppSelector } from '../../../../app/store';
import { Item, Serial, SerialClass } from '../../orderLookupModels';
import { getSerialInputHelperText } from './SerialUtilities';

export function SerialsAddFormTextField({
    itemData,
    setFormSerials,
    setFormValidated,
}: {
    itemData: Item | undefined;
    setFormSerials: (value: Serial[]) => void;
    setFormValidated: (value: boolean) => void;
}) {
    const orderId = useAppSelector(state => state.orderLookup.orderId);
    const [serialsValues, setSerialsValues] = React.useState<Serial[]>(
        itemData && !!orderId ? [new SerialClass(itemData?.ItemId, orderId)] : []
    );
    // Individual error types.
    const [serialsNumLinesValidated, setSerialsNumLinesValidated] = React.useState(true);
    const [serialRegexValidated, setSerialRegexValidated] = React.useState(true);
    const [serialDupsValidated, setSerialDupsValidated] = React.useState(true);
    // Error messages.
    const globalSerialCountErrorString = 'There are unequal amounts of serial numbers and quantity ordered.';
    const globalSerialRegexErrorString = 'There are some serials that do not match the required pattern.';
    const globalSerialDupsErrorString = 'There are duplicate serials found in item.';

    React.useEffect(() => {
        checkHasRegexErrors();
        setSerialsNumLinesValidated(passValidNumberOfSerials());
        setSerialDupsValidated(passSerialDuplicateCheck());
        setFormSerials(serialsValues); // Sets the values in the parent form.
    }, [serialsValues]);

    // Just checking for regex and duplicates for submit validation
    React.useEffect(() => {
        setFormValidated(serialRegexValidated && serialDupsValidated);
    }, [serialRegexValidated, serialDupsValidated]);

    const handleAddSerialRow = () => {
        if (itemData && !!orderId) {
            setSerialsValues([...serialsValues, new SerialClass(itemData?.ItemId, orderId)]);
        }
    };

    const handleDeleteSerialRow = (index: number) => {
        let tempSerialValues = [...serialsValues];
        tempSerialValues.splice(index, 1);
        setSerialsValues([...tempSerialValues]);
    };

    const serialsValidation = (serials: string, index: number, inputSerial2Field: boolean = false) => {
        setSerialsValues(prevSerials => {
            let serial1Value = inputSerial2Field ? serialsValues[index].Serial : serials;
            let serial2Value = inputSerial2Field ? serials : serialsValues[index].Serial2;
            const newSerials = [...prevSerials];
            if (itemData && !!orderId) {
                newSerials[index] = new SerialClass(itemData?.ItemId, orderId, serial1Value, serial2Value);
            }
            return newSerials;
        });
    };

    // Check inputs if values matches regex
    const passSerialRegex = (serialsInput: string, regexString: string | undefined) => {
        if (!!!serialsInput) {
            return true; // If the field is empty pass validation.
        }

        if (!!regexString) {
            let regex = new RegExp(regexString);
            return regex.test(serialsInput);
        }
        return true;
    };

    const fieldValuePassDupCheck = (serial: string | undefined, checkSerial2: boolean = false) => {
        if (itemData?.AllowSerialDups || !!!serial) {
            return true;
        }

        // Checking existing stored values
        if (!checkSerial2 && itemData?.Serials.some(s => s.Serial.trim() == serial.trim())) {
            return false;
        }
        if (checkSerial2 && itemData?.Serials.some(s => s.Serial2.trim() == serial.trim())) {
            return false;
        }

        // Checking current field values
        let serialCount = 0;
        serialsValues.forEach((s: Serial) => {
            if (
                (s.Serial.trim() == serial.trim() && !checkSerial2) ||
                (s.Serial2.trim() == serial.trim() && checkSerial2)
            ) {
                serialCount += 1;
            }
        });
        return serialCount < 2;
    };

    const passSerialDuplicateCheck = () => {
        if (!!itemData?.AllowSerialDups) {
            return true;
        }
        const serialValueSets: { [key: string]: { values: Set<string> } } = {
            serial: { values: new Set<string>() },
            serial2: { values: new Set<string>() },
        };

        let hasDuplicates = false;
        // Current form values
        serialsValues.map((serial: Serial) => {
            if (!!serial.Serial) {
                if (serialValueSets.serial.values.has(serial.Serial.trim())) {
                    hasDuplicates = true;
                } else {
                    serialValueSets.serial.values.add(serial.Serial.trim());
                }
            }
            if (!!serial.Serial2) {
                if (serialValueSets.serial2.values.has(serial.Serial2.trim())) {
                    hasDuplicates = true;
                } else {
                    serialValueSets.serial2.values.add(serial.Serial2.trim());
                }
            }
        });
        // Check for serials in this item
        itemData?.Serials.map((serial: Serial) => {
            if (!!serial.Serial) {
                if (serialValueSets.serial.values.has(serial.Serial.trim())) {
                    hasDuplicates = true;
                } else {
                    serialValueSets.serial.values.add(serial.Serial.trim());
                }
            }
            if (!!serial.Serial2) {
                if (serialValueSets.serial2.values.has(serial.Serial2.trim())) {
                    hasDuplicates = true;
                } else {
                    serialValueSets.serial2.values.add(serial.Serial2.trim());
                }
            }
        });

        return !hasDuplicates;
    };

    const checkHasRegexErrors = () => {
        setSerialRegexValidated(
            !serialsValues.some(
                serial =>
                    !passSerialRegex(serial.Serial, itemData?.SerialRegex) ||
                    !passSerialRegex(serial.Serial2, itemData?.Serial2Regex)
            )
        );
    };

    // Check if number of inputted serials equals to item qty ordered.
    const passValidNumberOfSerials = () => {
        let serialsCount = itemData?.Serials.length || 0;
        serialsValues.map((serial: Serial) => {
            // Don't count towards empty fields.
            if (!!serial.Serial || serial.Serial2) {
                serialsCount += 1;
            }
        });
        return serialsCount == itemData?.QuantityOrder;
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flex: 7, m: 1 }}>
                {serialsValues.map((serial: Serial, index) => (
                    <Box>
                        {itemData?.HasSerials2Field ? <Box>NO. {index + 1}</Box> : ''}
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <TextField
                                sx={{ flex: 7, m: 1 }}
                                value={serial?.Serial}
                                label={`SERIAL`}
                                variant="outlined"
                                size="small"
                                helperText={getSerialInputHelperText(
                                    passSerialRegex(serial.Serial, itemData?.SerialRegex),
                                    fieldValuePassDupCheck(serial.Serial),
                                    itemData?.SerialRegex
                                )}
                                fullWidth
                                error={
                                    !passSerialRegex(serial.Serial, itemData?.SerialRegex) ||
                                    !fieldValuePassDupCheck(serial.Serial)
                                }
                                onChange={event => {
                                    serialsValidation(event.target.value, index);
                                }}
                            />
                            {itemData?.HasSerials2Field ? (
                                <TextField
                                    sx={{ flex: 7, m: 1 }}
                                    value={serial?.Serial2}
                                    label={`SERIAL2`}
                                    variant="outlined"
                                    size="small"
                                    helperText={getSerialInputHelperText(
                                        passSerialRegex(serial.Serial2, itemData?.Serial2Regex),
                                        fieldValuePassDupCheck(serial.Serial2, true),
                                        itemData?.Serial2Regex
                                    )}
                                    fullWidth
                                    error={
                                        !passSerialRegex(serial.Serial2, itemData?.Serial2Regex) ||
                                        !fieldValuePassDupCheck(serial.Serial2, true)
                                    }
                                    onChange={event => {
                                        serialsValidation(event.target.value, index, true);
                                    }}
                                />
                            ) : (
                                ''
                            )}
                            {serialsValues.length > 1 ? (
                                <Button
                                    onClick={e => {
                                        handleDeleteSerialRow(index);
                                    }}
                                    color="error"
                                    sx={{ flex: 0.1 }}
                                >
                                    <DeleteForeverIcon />
                                </Button>
                            ) : (
                                ''
                            )}
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box sx={{ flex: 1, m: 1, justifyContent: 'center', display: 'flex' }}>
                <Button onClick={handleAddSerialRow}> Add Additional Serial </Button>
            </Box>
            <Box sx={{ flex: 1, m: 1 }}>
                {serialRegexValidated ? '' : <Alert severity="error">{globalSerialRegexErrorString}</Alert>}
                {serialDupsValidated ? '' : <Alert severity="error">{globalSerialDupsErrorString}</Alert>}
                {serialsNumLinesValidated ? '' : <Alert severity="warning">{globalSerialCountErrorString}</Alert>}
            </Box>
        </Box>
    );
}
