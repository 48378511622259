import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import React from 'react';
import { useAppDispatch } from '../../../../app/store';
import ItemDropdown from '../../../../common/components/dropdown/ItemDropdown';
import { setDialog } from '../../../notification/notificationSlice';
import ItemDetails from '../../../receivers/components/createDialog/formPartTwo/items/addItemDialog/ItemDetails';
import receiversApi from '../../../receivers/receiversApi';
import { SkuAdjustment } from '../../models';
import ItemEditDataGrid from './ItemEditDataGrid';

const AddItemDialog = ({
    isDialogOpen,
    client,
    onDialogClose,
    items,
    onAddNewItem,
    onProcessRowUpdate,
    onRowDelete,
}: {
    isDialogOpen: boolean;
    onDialogClose: () => void;
    client: string;
    items: SkuAdjustment[];
    onAddNewItem: (newSkuAdjustment: SkuAdjustment) => void;
    onProcessRowUpdate: (newSkuAdjustment: SkuAdjustment) => void;
    onRowDelete: (sku: string) => void;
}) => {
    const dispatch = useAppDispatch();
    const [selectedSku, setSelectedSku] = React.useState('');

    const getItemResponse = receiversApi.endpoints.getItem.useQuery(
        selectedSku
            ? {
                  MomCode: client,
                  Sku: selectedSku,
              }
            : skipToken
    );

    const fetchedItemData = getItemResponse.data?.Data[0];

    const handleAddNewItem = () => {
        if (items.find(item => item.Sku === selectedSku)) {
            dispatch(setDialog({ content: 'Cannot add same SKU', title: 'Item not allowed' }));
            return;
        }

        onAddNewItem({ Sku: selectedSku, AdjustmentAmount: 0 });
    };

    return (
        <Dialog onClose={onDialogClose} open={isDialogOpen} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>Add Receiver Item</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', my: 1 }}>
                    <ItemDropdown
                        client={client}
                        value={selectedSku}
                        onChange={(event, value) => {
                            setSelectedSku(value?.Item || '');
                        }}
                        sx={{ flex: 1 }}
                        textFieldProps={{ label: 'Stock Item' }}
                    />
                    <Button color="secondary" variant="contained" sx={{ m: 1 }} onClick={handleAddNewItem}>
                        Add Item
                    </Button>
                </Box>

                <ItemEditDataGrid
                    items={items}
                    onRowClick={sku => {
                        setSelectedSku(sku);
                    }}
                    onRowDelete={onRowDelete}
                    onProcessRowUpdate={onProcessRowUpdate}
                />
                <ItemDetails item={fetchedItemData} />
            </DialogContent>
        </Dialog>
    );
};

export default AddItemDialog;
