import { Box, Button, Card, CardActions, CardContent, CardMedia, TextField, Typography } from '@mui/material';
import Konva from 'konva';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files-ro';
import { Group, Rect } from 'react-konva';
import { Html } from 'react-konva-utils';
import { IMAGE_URL } from '../../../../app/services/api_constants';
import { useAppDispatch } from '../../../../app/store';
import noImage from '../../../../common/assets/images/NoImage.png';
import { ESCAPE_KEY, RETURN_KEY } from '../../../../common/utils/constants';
import { toBase64 } from '../../../../common/utils/typedCommonUtils';
import { setDialog } from '../../../notification/notificationSlice';
import { DraggableInstruction } from '../../models';
import ProcedureApi from '../../ProcedureApi';

const ACCEPTED_FILE_TYPES = ['JPG', 'PNG', 'GIF', 'JPEG'];

interface InstructionCardProps {
    instruction: DraggableInstruction;
    onClick: () => void;
    onDragEnd: (event: Konva.KonvaEventObject<DragEvent>) => void;
    isSelected: boolean;
    isSelectedSecond: boolean;
    onEscapeKeyPressed: () => void;
    onChange: (instruction: DraggableInstruction) => void;
    onAddNextInstructionMapClick: () => void;
    isAddingNewConnection: boolean;
    onAddItemButtonClick: () => void;
}

export const CARD_HEIGHT = 500;
export const CARD_WIDTH = 400;
const SIZE_OF_HEADER_BAR = 10;
const IMAGE_HEIGHT = CARD_HEIGHT * 0.58 - SIZE_OF_HEADER_BAR;

const InstructionCard = ({
    instruction,
    onClick,
    onDragEnd,
    isSelected,
    isSelectedSecond,
    onEscapeKeyPressed,
    onChange,
    onAddNextInstructionMapClick,
    isAddingNewConnection,
    onAddItemButtonClick,
}: InstructionCardProps) => {
    const dispatch = useAppDispatch();
    const [triggerDelete, deleteResponse] = ProcedureApi.endpoints.deleteInstruction.useMutation();
    const [triggerMarkAsStart, markAsStartResponse] = ProcedureApi.endpoints.markInstructionAsStart.useMutation();

    const handleTextInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if ((event.keyCode === RETURN_KEY && !event.shiftKey) || event.keyCode === ESCAPE_KEY) {
            onEscapeKeyPressed();
        }
    };

    const fillColor = (): string | CanvasGradient | undefined => {
        const isFinishCard = !!instruction.ReceiverItems.length;

        if (isSelected) return 'yellow';
        if (isSelectedSecond) return 'orange';
        if (instruction.IsStartOfProcedure) return 'lightgreen';
        if (isFinishCard) return 'red';
        return 'white';
    };

    const handleSetImage = async (file: File) => {
        const base64Encoded = await toBase64(file);
        onChange({ ...instruction, ImageContent: base64Encoded });
    };

    const getImageSource = () => {
        if (instruction.ImageContent) return instruction.ImageContent;
        if (!instruction?.Image) return noImage;
        else return `${IMAGE_URL}${instruction.Image}`;
    };

    return (
        <Group
            key={instruction.Id}
            id={String(instruction.Id)}
            onClick={onClick}
            x={instruction.CanvasPositionX || 0}
            y={instruction.CanvasPositionY || 0}
            draggable
            onDragEnd={onDragEnd}
            perfectDrawEnabled={false}
        >
            <Rect
                // rect size keeps leaking by a pixel, so forcefully take off the pixel here
                width={CARD_WIDTH - 1}
                height={CARD_HEIGHT}
                onMouseEnter={e => {
                    const container = e.target.getStage()?.container();
                    if (!container) return;
                    container.style.cursor = 'move';
                }}
                onMouseLeave={e => {
                    const container = e.target.getStage()?.container();
                    if (!container) return;
                    container.style.cursor = 'default';
                }}
                fill={fillColor()}
            />

            <Html divProps={{ style: { pointerEvents: 'none' } }} groupProps={{ y: SIZE_OF_HEADER_BAR }}>
                <CardMedia
                    component="img"
                    sx={{ objectFit: 'cover', height: `${IMAGE_HEIGHT}px`, width: `${CARD_WIDTH}px` }}
                    referrerPolicy="no-referrer"
                    src={getImageSource()}
                />
            </Html>

            <Html groupProps={{ y: SIZE_OF_HEADER_BAR + IMAGE_HEIGHT }}>
                <Card
                    sx={{
                        width: `${CARD_WIDTH}px`,
                        height: `${CARD_HEIGHT - IMAGE_HEIGHT - SIZE_OF_HEADER_BAR}px`,
                        display: 'flex',
                        flexDirection: 'column',
                        cursor: 'pointer',
                    }}
                    elevation={20}
                    onClick={onClick}
                >
                    <Box sx={{ flex: 1 }}>
                        <CardContent sx={{ '&:last-child': { pb: 0 } }}>
                            {isSelected ? (
                                <TextField
                                    label="Header"
                                    value={instruction.HeaderText}
                                    sx={{ mb: 1 }}
                                    onChange={event => {
                                        const newValue = event.currentTarget.value;
                                        onChange({ ...instruction, HeaderText: newValue });
                                    }}
                                    onKeyDown={handleTextInputKeyDown}
                                    fullWidth
                                    autoFocus
                                />
                            ) : (
                                <Typography gutterBottom variant="h5" component="div">
                                    {instruction.HeaderText}
                                </Typography>
                            )}

                            {isSelected ? (
                                <TextField
                                    label="Body"
                                    value={instruction.BodyText}
                                    onChange={event => {
                                        const newValue = event.currentTarget.value;
                                        onChange({ ...instruction, BodyText: newValue });
                                    }}
                                    onKeyDown={handleTextInputKeyDown}
                                    fullWidth
                                />
                            ) : (
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {instruction.BodyText}
                                </Typography>
                            )}
                        </CardContent>
                    </Box>

                    {isSelected && (
                        <CardActions sx={{ display: 'flex' }}>
                            <Button
                                size="small"
                                onClick={onAddNextInstructionMapClick}
                                color={isAddingNewConnection ? 'warning' : 'primary'}
                            >
                                Add Edge
                            </Button>
                            <FileUploader
                                handleChange={handleSetImage}
                                children={<Button size="small">Set Image</Button>}
                                types={ACCEPTED_FILE_TYPES}
                                onTypeError={() => {
                                    dispatch(
                                        setDialog({
                                            title: 'Invalid File Type',
                                            content: `Accepted file types: ${ACCEPTED_FILE_TYPES}`,
                                        })
                                    );
                                }}
                            />
                            <Button
                                size="small"
                                color="success"
                                onClick={() => {
                                    triggerMarkAsStart(instruction.Id);
                                }}
                            >
                                Set as Start
                            </Button>
                            <Button size="small" color="primary" onClick={onAddItemButtonClick}>
                                Add Item
                            </Button>
                            <Button
                                size="small"
                                color="error"
                                onClick={() => {
                                    triggerDelete(instruction.Id);
                                }}
                            >
                                Delete
                            </Button>
                        </CardActions>
                    )}
                </Card>
            </Html>
        </Group>
    );
};

export default InstructionCard;
