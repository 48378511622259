import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { CreatePlusIcon } from '../../../common/components';
import useNotificationByResponse from '../../../common/hooks/useNotificationByResponse';
import oldReceiversApi from '../oldReceiversApi';
import { setMomCode, setReceiverNumber } from '../oldReceiversSlice';
import { SubmissionForm } from '../receiversModels';
import ReceiversCreateDialog from './createDialog/ReceiversCreateDialog';
import ViewRecievers from './viewReceivers/ViewRecievers';

const ReceiversElement = () => {
    const dispatch = useAppDispatch();
    const [isReceiversDialogOpen, setIsReceiversDialogOpen] = useState(false);
    const [isInEditMode, setIsInEditMode] = useState(false);
    const { username } = useAppSelector(state => state.authentication);

    const initialForm: SubmissionForm = {
        Active: true,
        Client: '',
        Project: '',
        Po: '',
        RecDate: dayjs().format(),
        RecBy: username,
        Location: '',
        UnitType: '',
        Shipper: 'N/A',
        Carrier: 'N/A',
        Pack: '',
        Lading: '',
        StockTime: 0,
        LabelTime: 0,
        ShelfTime: 0,
        Palletcount: 0,
        ScDeliveryApt: true,
        ScPl: false,
        ScPlqty: false,
        ScCartonMark: false,
        ScBoxCondition: false,
        ScPalletCondition: false,
        Items: [],
        Comment: '',
        Played: false,
    };
    const [form, setForm] = useState<SubmissionForm>(initialForm);

    const [
        triggerGetReceiversStocks,
        stocksResponse,
    ] = oldReceiversApi.endpoints.getReceiverStocksDeprecated.useLazyQuery();
    const [
        triggerGetReceiversHistory,
        historyResponse,
    ] = oldReceiversApi.endpoints.getReceiversHistoryDeprecated.useLazyQuery();
    const handleContinueWithClickedRow = (newForm: SubmissionForm) => {
        triggerGetReceiversStocks(newForm.Client);
        triggerGetReceiversHistory({ momCode: newForm.Client, receiverNumber: newForm.Number || 0 });
        dispatch(setMomCode(newForm.Client));
        dispatch(setReceiverNumber(newForm.Number || 0));
        setForm(newForm);
        setIsInEditMode(true);
        setIsReceiversDialogOpen(true);
    };

    useNotificationByResponse({
        isFetching: stocksResponse.isFetching,
        isError: stocksResponse.isError,
        errorContent: 'Could not get stocks',
        isUninitialized: stocksResponse.isUninitialized,
    });

    return (
        <Box>
            <ViewRecievers form={form} handleContinueWithClickedRow={handleContinueWithClickedRow} />

            <ReceiversCreateDialog
                isReceiversDialogOpen={isReceiversDialogOpen}
                setIsReceiversDialogOpen={setIsReceiversDialogOpen}
                form={form}
                setForm={setForm}
                isInEditMode={isInEditMode}
                resetForm={() => {
                    setForm(initialForm);
                }}
            />

            <CreatePlusIcon
                testId="create-receiver-button"
                label="add"
                buttonIcon={AddIcon}
                onClick={() => {
                    setIsReceiversDialogOpen(true);
                    setIsInEditMode(false);
                }}
            />
        </Box>
    );
};

export default ReceiversElement;
