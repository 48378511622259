import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ReceiverItemRecord } from '../../../../procedure/models';

const SkuAdjustmentTable = ({ targetReceiverItemAmounts }: { targetReceiverItemAmounts: ReceiverItemRecord[] }) => {
    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Sku</TableCell>
                    <TableCell align="right">Target Adjustment Amount</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(targetReceiverItemAmounts || []).map(targetReceiverItemAmount => (
                    <TableRow key={targetReceiverItemAmount.Sku}>
                        <TableCell>{targetReceiverItemAmount.Sku}</TableCell>
                        <TableCell align="right">
                            {targetReceiverItemAmount.CurrentProgressAmount} /{' '}
                            {targetReceiverItemAmount.AdjustmentAmount}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default SkuAdjustmentTable;
