import { Box } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useRef, useState } from 'react';
import type {} from 'redux-thunk/extend-redux';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { setDialog, setSnackbar } from '../../notification/notificationSlice';
import { QualityControlDrawer } from '../../qualityControl/components/QualityControlDrawer';
import { parseInitialQCScan } from '../../qualityControl/util/handleInitialScan';
import rmaApi from '../rmaApi';
import {
    RmaAllScans,
    TransformedGetRmaItem,
    TransformedGetRmaItemSerialVerify,
    TransformedGetRmaItemsResponse,
} from '../rmaModels';
import { addToAllSerials, resetToGetSerialsFor } from '../rmaSlice';

const RmaScanner = () => {
    const rmaState = useAppSelector(state => state.rma);
    const getRmaItemsResponse =
        (rmaApi.endpoints.getRmaItems.useQuery(
            rmaState.rmaNumber ? { momCode: rmaState.momCode, rmaNumber: rmaState.rmaNumber } : skipToken
        ).data as TransformedGetRmaItemsResponse) ||
        ({
            MomCode: '',
            RmaNumber: 0,
            OrderNumber: 0,
            Items: [],
        } as TransformedGetRmaItemsResponse);

    const dispatch = useAppDispatch();

    const [serialInput, setSerialInput] = useState('');
    const [serialDrawerOpen, setSerialDrawerOpen] = useState(false);
    const [allScans, setAllScans] = useState<RmaAllScans>();

    const [currItemSerialList, setCurrItemSerialList] = useState<string[]>([]);

    const upcInputRef = useRef(null);
    const serialTimeout = useRef<ReturnType<typeof setInterval> | null>(null);

    useEffect(() => {
        return () => {
            if (serialTimeout?.current) clearInterval(serialTimeout.current);
        };
    }, []);

    useEffect(() => {
        if (rmaState.upcToGetSerialsFor) {
            const parsedData: RmaAllScans = {
                item: getRmaItemsResponse.Items.find(
                    item =>
                        item.Upc === rmaState.upcToGetSerialsFor &&
                        !item.IsCompleted &&
                        item.QuantityIndex === rmaState.quantityIndexToGetSerialsFor
                ) as TransformedGetRmaItem,
            };
            setAllScans(parsedData as RmaAllScans);
        }
    }, [rmaState]);

    useEffect(() => {
        if (allScans?.item) {
            const isFirstSerialGood = allScans.serial && isSerialGood(allScans.serial);
            const isSecondSerialGood = allScans.serial2 && isSerialGood(allScans.serial2);
            let newCurrItemSerialList = [...currItemSerialList];

            if (isFirstSerialGood && isSecondSerialGood) {
                newCurrItemSerialList[newCurrItemSerialList.length] = allScans.serial || '';
                newCurrItemSerialList[newCurrItemSerialList.length] = allScans.serial2 || '';
            } else if (isFirstSerialGood) {
                newCurrItemSerialList[newCurrItemSerialList.length] = allScans.serial || '';
            }

            setCurrItemSerialList(newCurrItemSerialList);
            const isThereMoreSerialsToScan =
                allScans?.item && newCurrItemSerialList.length < allScans.item?.SerialVerifies.length;
            if (isThereMoreSerialsToScan) promptForSerialScan();
            else markAsSuccessfulItemScan(newCurrItemSerialList);
        }
    }, [allScans]);

    const isSerialGood = (serialToProcess: string) => {
        if (!serialToProcess) {
            dispatch(setDialog({ content: `No value was scanned` }));
            return false;
        }

        const isSerialScannedInCurrentQC = //currItemSerialList always has serialToProcess since it's added at start
            rmaState.allSerials.includes(serialToProcess) || currItemSerialList.includes(serialToProcess);
        const currSerialVerify =
            allScans?.item &&
            allScans.item?.SerialVerifies.find(
                serialVerify =>
                    serialVerify.ScanOrder === currItemSerialList.length ||
                    serialVerify.ScanOrder === currItemSerialList.length + 1
            );
        if (!currSerialVerify) {
            dispatch(setDialog({ content: `${serialToProcess} item does not have serial verify` }));
            return false;
        }
        const serialRegex = new RegExp(currSerialVerify.Regex);
        const isSerialMatchingRegex = serialRegex.test(serialToProcess);

        if (isSerialScannedInCurrentQC) {
            dispatch(setDialog({ content: `${serialToProcess} was already scanned` }));
            return false;
        } else if (!isSerialMatchingRegex) {
            dispatch(setDialog({ content: `${serialToProcess} is invalid serial pattern` }));
            return false;
        }
        return true;
    };

    const promptForSerialScan = () => {
        dispatch(
            setSnackbar({
                content: `Do additional scan for ${allScans?.item?.Upc}`,
                severity: 'warning',
            })
        );

        setSerialInput('');
        if (serialDrawerOpen) {
            setSerialDrawerOpen(false);
            serialTimeout.current = setTimeout(() => {
                setSerialDrawerOpen(true);
            }, 500);
        } else {
            setSerialDrawerOpen(true);
        }
    };

    const markAsSuccessfulItemScan = (inputCurrItemSerialList: string[] = currItemSerialList) => {
        dispatch(
            setSnackbar({
                content: `Scanned ${allScans?.item?.Upc} with ${serialInput} successfully!`,
                severity: 'success',
            })
        );
        recordFinalSerialScanInSet(inputCurrItemSerialList);
        updateDataDueToSuccessfulScan(inputCurrItemSerialList);
    };

    const handleSerialSubmitButtonClicked = () => {
        const mom = getRmaItemsResponse.MomCode;
        let parsedData = parseInitialQCScan(mom, serialInput.trim(), false) as RmaAllScans;
        setAllScans(prevScan => {
            return { ...prevScan, ...parsedData };
        });
    };

    const recordFinalSerialScanInSet = (inputCurrItemSerialList: string[] = currItemSerialList) => {
        dispatch(addToAllSerials(inputCurrItemSerialList));

        resetSerialDrawer();
        setTimeout(() => {
            if (upcInputRef && upcInputRef.current) {
                ((((upcInputRef.current as unknown) as HTMLElement)?.firstChild as HTMLElement)
                    ?.firstChild as HTMLElement)?.focus();
            }
        }, 100);
    };

    const updateDataDueToSuccessfulScan = (serials: string[] = [], inputUpcCode = allScans?.item?.Upc) => {
        dispatch(
            setSnackbar({
                content: `Scanned ${allScans?.item?.Upc} successfully!`,
                severity: 'success',
            })
        );

        let newSerialVerifies: TransformedGetRmaItemSerialVerify[] = [];
        if (!allScans?.item) return;
        for (let i = 0; i < allScans.item.SerialVerifies.length; i++) {
            newSerialVerifies.push({ ...allScans.item.SerialVerifies[i], ScanValue: serials[i] });
        }

        let isItemFound = false;
        const newItems = getRmaItemsResponse.Items.map(item => {
            if (
                !isItemFound &&
                item.Upc === inputUpcCode &&
                !item.IsCompleted &&
                item.QuantityIndex === rmaState.quantityIndexToGetSerialsFor
            ) {
                isItemFound = true;
                return { ...item, IsCompleted: true, SerialVerifies: newSerialVerifies };
            } else return item;
        });
        dispatch(
            rmaApi.util.updateQueryData(
                'getRmaItems',
                { momCode: rmaState.momCode, rmaNumber: rmaState.rmaNumber },
                newData => {
                    newData.Items = newItems;
                }
            )
        );

        dispatch(resetToGetSerialsFor());
    };

    const resetSerialDrawer = () => {
        setSerialDrawerOpen(false);
        setSerialInput('');
        setCurrItemSerialList([]);
        dispatch(resetToGetSerialsFor());
    };

    return (
        <Box sx={{ p: '10px 24px', marginBottom: 2, display: 'flex', flexDirection: 'row' }}>
            <QualityControlDrawer
                serialDrawerOpen={serialDrawerOpen}
                setSerialDrawerOpen={setSerialDrawerOpen}
                serialInput={serialInput}
                setSerialInput={setSerialInput}
                handleDrawerClose={resetSerialDrawer}
                handleSerialSubmitButtonClicked={handleSerialSubmitButtonClicked}
                alias={
                    allScans?.item?.SerialVerifies.find(
                        serialVerify => serialVerify.ScanOrder === currItemSerialList.length
                    )?.Alias || 'Serial Input'
                }
            />
        </Box>
    );
};

export default RmaScanner;
