import { Box, Button } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { setDialog } from '../../../../notification/notificationSlice';
import FormDivider from '../FormDivider';
import ConnectProcedureDialog from './ConnectProcedureDialog';
import ProceduresList from './ProceduresList';

const Procedures = ({ isDisabled }: { isDisabled: boolean }) => {
    const [isConnectProcedureDialogOpen, setIsConnectProcedureDialog] = React.useState(false);

    const [addTargetAdjustmentAmountProcedureId, setAddTargetAdjustmentAmountProcedure] = React.useState<
        number | undefined
    >();
    const client = useAppSelector(state => state.workOrder.form.MomCode);
    const dispatch = useAppDispatch();

    return (
        <Box>
            <FormDivider text={'SWROPs'} />

            <Button
                color="secondary"
                sx={{ display: isDisabled ? 'none' : '' }}
                onClick={() => {
                    if (!client)
                        dispatch(
                            setDialog({ content: 'Client is required to connect a SWROP', title: 'Invalid Client' })
                        );
                    else setIsConnectProcedureDialog(true);
                }}
                variant="contained"
                fullWidth
            >
                Connect New SWROP
            </Button>
            <ProceduresList
                onRowClick={procedureId => {
                    if (isDisabled) return;
                    setAddTargetAdjustmentAmountProcedure(procedureId);
                }}
                isDisabled={isDisabled}
            />
            <ConnectProcedureDialog
                isDialogOpen={isConnectProcedureDialogOpen}
                onClose={(clickedProcedureId: number | undefined) => {
                    setAddTargetAdjustmentAmountProcedure(clickedProcedureId);
                    setIsConnectProcedureDialog(false);
                }}
            />
        </Box>
    );
};

export default Procedures;
