import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Collapse, IconButton, Link, TableCell, TableRow, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import dayjs from 'dayjs';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { setDialog } from '../../../../notification/notificationSlice';
import ProcedureApi from '../../../../procedure/ProcedureApi';
import { WorkOrderToProcedureMap } from '../../../workOrderModels';
import { overrideForm } from '../../../workOrderSlice';
import CreateReceiverDialog from './CreateReceiverDialog';
import ProcedureAddItemDialog from './ProcedureAddItemDialog';
import SkuAdjustmentTable from './SkuAdjustmentTable';

const ProceduresListRow = ({
    workOrderToProcedureMap,
    isDisabled,
}: {
    workOrderToProcedureMap: WorkOrderToProcedureMap;
    isDisabled: boolean;
}) => {
    const [open, setOpen] = React.useState(false);

    const [isAddItemDialogOpen, setIsAddItemDialogOpen] = React.useState(false);
    const [isCreateReceiverDialogOpen, setIsCreateReceiverDialogOpen] = React.useState(false);

    const getWorkOrderToProcedureMapResponse = ProcedureApi.endpoints.getWorkOrderToProcedureMap.useQuery(
        workOrderToProcedureMap.Id ? workOrderToProcedureMap.Id : skipToken
    );

    const getProcedureResponse = ProcedureApi.endpoints.getProcedure.useQuery(
        !workOrderToProcedureMap.Id && workOrderToProcedureMap.ProcedureId
            ? workOrderToProcedureMap.ProcedureId
            : skipToken
    );

    const dataToUse = getWorkOrderToProcedureMapResponse.data
        ? getWorkOrderToProcedureMapResponse.data
        : getProcedureResponse.data;

    const workOrderToProcedureMaps = useAppSelector(state => state.workOrder.form.WorkOrderToProcedureMaps);
    const dispatch = useAppDispatch();

    const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
        event?.stopPropagation();
        const newProceduresList = [...workOrderToProcedureMaps];

        const matchingWorkorderToProcedureMapIndex = workOrderToProcedureMaps.findIndex(
            map => map.Id === workOrderToProcedureMap.Id
        );

        if (newProceduresList[matchingWorkorderToProcedureMapIndex]?.Id) {
            newProceduresList[matchingWorkorderToProcedureMapIndex] = {
                ...newProceduresList[matchingWorkorderToProcedureMapIndex],
                DeletedAt: dayjs().format(),
            };
        } else {
            newProceduresList.splice(matchingWorkorderToProcedureMapIndex, 1);
        }

        dispatch(
            overrideForm({
                WorkOrderToProcedureMaps: newProceduresList,
            })
        );
    };

    const handleBeginButtonClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        if (workOrderToProcedureMap?.Id) {
            window.open(`/swrop/${workOrderToProcedureMap?.Id}`);
        } else {
            dispatch(setDialog({ content: 'Must save current changes before beginning SWROP', title: 'Save Changes' }));
        }
    };

    const shouldRenderSkuAdjustmentTable =
        workOrderToProcedureMap?.TargetReceiverItemAmounts.filter(amount => !amount.DeletedAt).length > 0 &&
        workOrderToProcedureMap.Id;

    return (
        <React.Fragment>
            <TableRow
                sx={{ '& > *': { borderBottom: 'unset' } }}
                onClick={event => {
                    event.stopPropagation();
                    if (!isDisabled) setIsAddItemDialogOpen(true);
                }}
            >
                <TableCell>
                    {shouldRenderSkuAdjustmentTable && (
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={event => {
                                event.stopPropagation();
                                setOpen(!open);
                            }}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell component="th" scope="row">
                    {dataToUse?.Title}
                </TableCell>
                <TableCell>{dataToUse?.CreatedBy}</TableCell>
                <TableCell>
                    <Button onClick={handleBeginButtonClicked}>Begin</Button>
                </TableCell>
                <TableCell align="right" sx={{ display: isDisabled ? 'none' : 'block' }}>
                    <IconButton edge="end" onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                {shouldRenderSkuAdjustmentTable && (
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <SkuAdjustmentTable
                                    targetReceiverItemAmounts={
                                        getWorkOrderToProcedureMapResponse?.data?.Instructions.flatMap(instruction => {
                                            const result = instruction.ReceiverItems.map(item => {
                                                return {
                                                    ...item,
                                                    AdjustmentAmount:
                                                        workOrderToProcedureMap.TargetReceiverItemAmounts.find(
                                                            amount => amount.Sku === item.Sku
                                                        )?.AdjustmentAmount || 0,
                                                };
                                            });
                                            return result;
                                        }) || []
                                    }
                                />

                                <Box sx={{ display: 'flex', m: 1 }}>
                                    <Box sx={{ flex: 1 }}>
                                        {getWorkOrderToProcedureMapResponse.data?.ReceiverIds.length ? (
                                            <Typography>
                                                Created Receiver
                                                {getWorkOrderToProcedureMapResponse.data?.ReceiverIds.length > 1
                                                    ? 's'
                                                    : ''}
                                                :
                                                {getWorkOrderToProcedureMapResponse.data?.ReceiverIds.map(receiver => (
                                                    <>
                                                        {' '}
                                                        <Link href={`/receivers/${receiver}`} target="_blank">
                                                            #{receiver}
                                                        </Link>{' '}
                                                    </>
                                                ))}
                                            </Typography>
                                        ) : (
                                            <></>
                                        )}
                                    </Box>

                                    <Button
                                        size="small"
                                        onClick={event => {
                                            event.stopPropagation();
                                            setIsCreateReceiverDialogOpen(true);
                                        }}
                                        sx={{ display: isDisabled ? 'none' : '' }}
                                    >
                                        Create Receiver
                                    </Button>
                                </Box>
                            </Box>
                        </Collapse>
                    </TableCell>
                )}
            </TableRow>

            <ProcedureAddItemDialog
                isOpen={isAddItemDialogOpen}
                workOrderToProcedureMap={workOrderToProcedureMap}
                onClose={() => setIsAddItemDialogOpen(false)}
            />

            <CreateReceiverDialog
                isOpen={isCreateReceiverDialogOpen}
                onClose={() => setIsCreateReceiverDialogOpen(false)}
                workOrderToProcedureMap={workOrderToProcedureMap}
            />
        </React.Fragment>
    );
};

export default ProceduresListRow;
