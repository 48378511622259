import { SxProps, TextFieldProps } from '@mui/material';
import { useAppSelector } from '../../../../../app/store';
import NumberTextfieldV2 from '../../../../../common/components/TextField/NumberTextfieldV2';
import { RECEIVERS_DIALOG_DEFAULT_TEXTFIELD_STYLING } from '../../../styles';
import { Rule } from '../../../receiversModels';

const ReceiverDialogNumberTextField = ({
    value,
    onChange,
    textFieldProps = {},
    sx = RECEIVERS_DIALOG_DEFAULT_TEXTFIELD_STYLING,
    rule = {
        Regex: '',
        IsDisabled: false,
        IsRequired: false,
    },
}: {
    value: number;
    onChange: (input: number) => void;
    textFieldProps?: TextFieldProps;
    sx?: SxProps;
    rule?: Rule;
}) => {
    const mode = useAppSelector(state => state.receivers.mode);

    const combinedIsDisabled = rule.IsDisabled || textFieldProps?.disabled;
    const combinedIsRequired = rule.IsRequired || textFieldProps?.required;

    return (
        <NumberTextfieldV2
            value={value}
            key={value}
            onChange={newValue => {
                onChange(newValue);
            }}
            textFieldProps={{
                disabled: combinedIsDisabled,
                required: combinedIsRequired,
                helperText: ' ',
                ...textFieldProps,
                variant: 'filled',
                InputProps: {
                    readOnly: mode === 'View',
                },
                sx: sx,
            }}
        />
    );
};

export default ReceiverDialogNumberTextField;
