import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { GET_SERIAL_LOCATION_URL } from '../../app/services/api_constants';
import { dreamBaseQueryWithReauth } from '../../app/services/dreamBaseQueryWithReauth';
import { GetSerialLocationRequest, LocationAssignmentRecord } from './serialLocationModels';

export default createApi({
    reducerPath: 'serialLocationApi',
    baseQuery: dreamBaseQueryWithReauth,
    endpoints: builder => ({
        getSerialLocations: builder.query<LocationAssignmentRecord[], GetSerialLocationRequest>({
            query: param => ({
                url: GET_SERIAL_LOCATION_URL,
                method: 'GET',
                params: param,
            }),
        }),
    }),
});
