import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router';
import { DraggableInstruction, ReceiverItemRecord, SkuAdjustment } from '../../models';
import ProcedureApi from '../../ProcedureApi';
import AddItemDialog from './AddItemDialog';

const InstructionAddItemDialog = ({
    instruction,
    onDialogClose,
}: {
    instruction: DraggableInstruction;
    onDialogClose: () => void;
}) => {
    const { procedureId } = useParams();
    const castedProcedureId = procedureId ? Number(procedureId) : null;
    const procedureResponse = ProcedureApi.endpoints.getProcedure.useQuery(
        castedProcedureId ? castedProcedureId : skipToken
    );

    const [
        triggerUpsertReceiverItem,
        upsertReceiverItemResponse,
    ] = ProcedureApi.endpoints.upsertReceiverItem.useMutation();

    const handleAddNewItem = (newSkuAdjustment: SkuAdjustment) => {
        const newItem: ReceiverItemRecord = {
            Sku: newSkuAdjustment.Sku,
            AdjustmentAmount: 0,
            CurrentProgressAmount: 0,
            InstructionId: instruction.Id,
        };

        triggerUpsertReceiverItem(newItem);
    };

    const [
        triggerDeleteReceiverItem,
        deleteReceiverItemResponse,
    ] = ProcedureApi.endpoints.deleteReceiverItem.useMutation();

    const handleDeleteClick = (sku: string) => {
        const newItem = instruction?.ReceiverItems.find(row => row.Sku === sku);
        if (!newItem) return;

        triggerDeleteReceiverItem(newItem.Id);
    };

    const processRowUpdate = (newRow: SkuAdjustment) => {
        const newItem = instruction?.ReceiverItems.find(row => row.Sku === newRow.Sku);
        if (newItem) triggerUpsertReceiverItem({ ...newItem, AdjustmentAmount: newRow.AdjustmentAmount || 0 });
    };

    if (!instruction) return;

    return (
        <AddItemDialog
            isDialogOpen={!!instruction}
            client={procedureResponse.data?.Client || ''}
            onDialogClose={onDialogClose}
            items={instruction?.ReceiverItems}
            onAddNewItem={handleAddNewItem}
            onProcessRowUpdate={processRowUpdate}
            onRowDelete={handleDeleteClick}
        />
    );
};

export default InstructionAddItemDialog;
