import { Button, Card, CardActions, CardContent } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router';
import SkuAdjustmentTable from '../../../workOrder/components/Form/Procedure/SkuAdjustmentTable';
import ProcedureApi from '../../ProcedureApi';
import ProcedureDetailsCard from '../ProcedureDetailsCard';

const BeginSession = () => {
    const { workOrderToProcedureMapId } = useParams();
    const castedWorkOrderToProcedureMapId = workOrderToProcedureMapId ? Number(workOrderToProcedureMapId) : null;

    const getWorkOrderToProcedureMapResponse = ProcedureApi.endpoints.getWorkOrderToProcedureMap.useQuery(
        castedWorkOrderToProcedureMapId ? castedWorkOrderToProcedureMapId : skipToken
    );

    const [triggerStartSession, startSessionResponse] = ProcedureApi.endpoints.startSession.useMutation();

    return (
        <>
            <ProcedureDetailsCard />

            <Card>
                <CardContent>
                    {' '}
                    <SkuAdjustmentTable
                        targetReceiverItemAmounts={
                            getWorkOrderToProcedureMapResponse?.data?.Instructions.flatMap(
                                instruction => instruction.ReceiverItems
                            ) || []
                        }
                    />
                </CardContent>
                <CardActions>
                    {' '}
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                            if (castedWorkOrderToProcedureMapId)
                                triggerStartSession({ WorkOrderToProcedureMapId: castedWorkOrderToProcedureMapId });
                        }}
                    >
                        Begin Procedure
                    </Button>
                </CardActions>
            </Card>
        </>
    );
};

export default BeginSession;
