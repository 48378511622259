import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router';
import ProcedureApi from '../ProcedureApi';

const useGetCreatedProcedureRecord = () => {
    const { workOrderToProcedureMapId } = useParams();
    const castedWorkOrderToProcedureMapId = workOrderToProcedureMapId ? Number(workOrderToProcedureMapId) : null;
    const workOrderToProcedureMapResponse = ProcedureApi.endpoints.getWorkOrderToProcedureMap.useQuery(
        castedWorkOrderToProcedureMapId ? castedWorkOrderToProcedureMapId : skipToken
    );

    const { procedureId } = useParams();
    const castedProcedureId = procedureId ? Number(procedureId) : null;
    const procedureResponse = ProcedureApi.endpoints.getProcedure.useQuery(
        castedProcedureId ? castedProcedureId : skipToken
    );

    return workOrderToProcedureMapResponse?.data ? workOrderToProcedureMapResponse : procedureResponse;
};

export default useGetCreatedProcedureRecord;
